import { YODL_ENS_DOMAIN } from '@frontend/common';
import { ensUsernameSchema } from '@frontend/shared/validation';
import generateFunnyCryptoUsername from '@frontend/utils/generateFunnyCryptoUsername';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIsSubnameAvailable } from '@justaname.id/react';
import { Cross2Icon } from '@radix-ui/react-icons';
import {
  Button,
  Card,
  Flex,
  Grid,
  Spinner,
  Text,
  TextField,
} from '@radix-ui/themes';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface JustanameAddressInputProps {
  isLoading: boolean;
  isUsernameSet?: boolean;
  currentUsername?: string;
  revokeSubnameMutation: () => void;
  onUsernameChange?: (username: string) => void; // Optional callback to pass username to parent
  isValid?: (isValid: boolean) => void;
}

interface FormValues {
  username: string;
}

const JustanameAddressInput = ({
  isLoading = false,
  isUsernameSet = false,
  revokeSubnameMutation,
  onUsernameChange,
  currentUsername,
  isValid,
}: JustanameAddressInputProps) => {
  const { register, setValue, watch, formState, trigger } = useForm<FormValues>(
    {
      resolver: zodResolver(ensUsernameSchema),
      defaultValues: { username: currentUsername || '' },
    },
  );

  const { isSubnameAvailable } = useIsSubnameAvailable({
    username: watch('username'),
  });

  const username = watch('username');
  const [error, setError] = useState<string | undefined>(undefined);

  const isYodlEns = username?.endsWith(YODL_ENS_DOMAIN);

  useEffect(() => {
    if (isLoading) return;

    const generatedUsername = currentUsername || generateFunnyCryptoUsername();
    setValue('username', generatedUsername);
    onUsernameChange?.(generatedUsername);
    trigger('username').then((r) => console.log(r));
  }, [
    currentUsername,
    isLoading,
    isUsernameSet,
    onUsernameChange,
    setValue,
    trigger,
  ]);

  useEffect(() => {
    onUsernameChange?.(username);
  }, [username, onUsernameChange]);

  useEffect(() => {
    if (!isUsernameSet && !isLoading) {
      const { success, error } = ensUsernameSchema.safeParse({ username });
      setError(success ? undefined : error?.errors[0]?.message);
    }
  }, [username, isUsernameSet, isLoading]);

  useEffect(() => {
    isValid?.(isUsernameSet || formState.isValid);
  }, [formState.isValid, isUsernameSet, isValid]);

  const availabilityTextMap = {
    available: 'This handle is available',
    notAvailable: 'This handle is not available',
    loading: 'Checking availability...',
  };

  return (
    <Grid>
      <Card variant="classic" size="2">
        <Grid gap="3">
          <Text size="3" weight="bold" as="p">
            Handle
          </Text>

          <TextField.Root
            radius="full"
            disabled={isUsernameSet || isLoading}
            className="shadow-sm flex items-center"
            placeholder="Choose yodl handle"
            {...register('username')}
          >
            <TextField.Slot />

            {isLoading ? (
              <TextField.Slot>
                <Spinner size="1" />
              </TextField.Slot>
            ) : (
              <TextField.Slot>
                <Button
                  radius="full"
                  variant="soft"
                  color={isUsernameSet ? 'red' : 'gray'}
                  size="1"
                  onClick={revokeSubnameMutation}
                  className="hover:opacity-90 transition-opacity"
                >
                  {isUsernameSet && isYodlEns
                    ? 'revoke'
                    : `.${YODL_ENS_DOMAIN}`}
                  {isUsernameSet && isYodlEns && (
                    <Cross2Icon className="ml-1" />
                  )}
                </Button>
              </TextField.Slot>
            )}
          </TextField.Root>

          <Flex justify="end">
            {!isUsernameSet && !isEmpty(username) && !error && (
              <Text
                size="2"
                color={
                  isLoading ? 'gray' : isSubnameAvailable ? 'green' : 'red'
                }
                className="font-medium"
              >
                {isLoading
                  ? availabilityTextMap.loading
                  : isSubnameAvailable
                    ? availabilityTextMap.available
                    : availabilityTextMap.notAvailable}
              </Text>
            )}

            {error && !isLoading && (
              <Text size="2" color="red" className="font-medium">
                {error}
              </Text>
            )}
          </Flex>
        </Grid>
      </Card>
    </Grid>
  );
};

export default JustanameAddressInput;
