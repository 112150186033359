import { isAddress } from 'ethers';
import { z } from 'zod';

export const Address = z
  .string()
  .refine((value) => !value.endsWith('.eth'), {
    message: 'ENS domain not yet supported, coming soon.',
    path: [],
  })
  .refine((value) => /^0x[a-fA-F0-9]{40}$/.test(value), {
    message: 'Invalid address',
    path: [],
  });

export const SavedLink = z.array(
  z.object({
    alias: z.string(),
    identifier: z.string(),
    source: z.string(),
  }),
);
// Define the Zod schema for validation
export const ensUsernameSchema = z.object({
  username: z
    .string()
    .min(6, { message: 'Username must be at least 6 characters' })
    .regex(/^[a-zA-Z0-9_]+$/, {
      message: 'Username can only contain letters, numbers, and underscores',
    })
    .refine((value) => !isAddress(value), {
      message: 'Username cannot be an Ethereum address',
    })
    .refine((value) => !restrictedUsernames.includes(value), {
      message: 'Username is not allowed',
    }),
});

const restrictedUsernames = [
  'admin',
  'root',
  'moderator',
  'system',
  'null',
  'undefined',
  'test',
  'user',
  'anonymous',
  'guest',
  'support',
  'helpdesk',
  'superuser',
  'owner',
  'bot',
  'ai',
  'dev',
  'debug',
  'error',
  '404',
  'noreply',
  'contact',
  'spam',
  'phishing',
  'hack',
  'script',
  'admin123',
  'superadmin',
  'system32',
  'localhost',
  'master',
  'slave',
  'reserved',
  'banned',
  'illegal',
  'offensive',
  'racist',
  'sexist',
  'explicit',
  'nsfw',
  'admin',
  'administrator',
  'sysadmin',
  'root',
  'superuser',
  'superadmin',
  'moderator',
  'mod',
  'manager',
  'boss',
  'chief',
  'lead',
  'adminstr',
  'console',
  'operator',
  'owner',
  'coadmin',
  'headadmin',
  'adminteam',
  'sysop',
  'security',
  'control',
  'masteradmin',
  'webmaster',
  'itadmin',
  'domainadmin',
  'serveradmin',
  'networkadmin',
  'mainadmin',
  'globaladmin',
  'siteadmin',
  'hostmaster',
  'adminpanel',
  'adm',
  'adm1n',
  'aDM1N',
  'systemadmin',
  'backend',
  'authadmin',
  'privileged',
  'elevated',
  'developer',
  'dev',
  'devops',
  'devops',
];
