'use client';

import { AvatarList } from '@frontend/components/common/AvatarList';
import { FullScreenDialogHeaderHeight } from '@frontend/components/FullScreenDialog';
import JustanameAddressInput from '@frontend/components/JustanameAddressInput';
import { hasSupportedChain } from '@frontend/common';
import parseTokenImages from '@frontend/utils/parseImages';
import { splitDomain, useRevokeSubname } from '@justaname.id/react';
import {
  Badge,
  Button,
  Card,
  Flex,
  Grid,
  ScrollArea,
  Separator,
  Text,
  VisuallyHidden,
} from '@radix-ui/themes';
import { getChain, getTokens, TokenInfo } from '@yodlpay/tokenlists';
import Image from 'next/image';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDebounce } from '@frontend/client/hooks';
import useAccountEns from '@frontend/client/hooks/useAccountEns';
import {
  useSignedPreferences,
  useSignPreferences,
} from '@frontend/client/hooks/useSignedPreferences';
import {
  ALL_PREFERENCE_SECTIONS,
  isUSDStableSymbol,
  PreferenceSection,
  SUPPORTED_CHAINS,
  YODL_ENS_DOMAIN,
} from '@frontend/common';
import { isMobile } from '@frontend/utils/isMobile';
import { GearIcon } from '@radix-ui/react-icons';
import assert from 'assert';
import { mainnet } from 'viem/chains';
import { useAccount, useSwitchChain } from 'wagmi';

const IMAGE_SIZE = 8 * 4;

const savePreferencesButtonColorMap = {
  success: 'grass',
  error: 'red',
};

const savePreferencesButtonText = {
  idle: 'Confirm',
  success: 'Close',
  error: 'Try again',
};

interface PaymentPreferencesSectionProps {
  handleOnClose: () => void;
}

export const PaymentPreferencesSection = ({
  handleOnClose,
}: PaymentPreferencesSectionProps) => {
  const { address, chainId } = useAccount();
  const { switchChain, isPending: switchChainPending } = useSwitchChain();
  const allTokens = getTokens();

  const { primaryName, isPrimaryNameLoading: isLoading } = useAccountEns();
  const [username, setUsername] = useState(primaryName || '');
  const debouncedUsername = useDebounce(username, 500);

  const [isValid, setIsValid] = useState(false);

  const [currentTab, setCurrentTab] = useState<'token' | 'chain'>('token');
  const [selectedTokens, setSelectedTokens] = useState<TokenInfo[]>([]);
  // We set the chains automatically based on the selected tokens
  const [selectedChains, setSelectedChains] = useState<number[]>([]);
  const [selectedSection, setSelectedSection] =
    useState<PreferenceSection>('all');

  const { preferences } = useSignedPreferences({
    addressOrEns: address,
  });

  const { mutate, status, error } = useSignPreferences({
    username: debouncedUsername.debouncedValue,
    isRegistered: !!primaryName,
  });

  const tokensMap = useMemo(() => {
    const supportedTokens = allTokens.filter((token) => {
      return isUSDStableSymbol(token.symbol) && hasSupportedChain(token);
    });

    // remove duplicates
    const uniqueTokens = supportedTokens.filter(
      (token, index, self) =>
        index ===
        self.findIndex(
          (t) => t.symbol.toLowerCase() === token.symbol.toLowerCase(),
        ),
    );

    // Add the chain id to the token if it's same
    return uniqueTokens.map((token) => {
      const chainId = token.chainId;
      const chains = supportedTokens
        .filter((t) => t.symbol === token.symbol)
        .map((t) => t.chainId)
        .map((chainId) => getChain(chainId));

      return {
        ...token,
        chainId,
        chains,
      };
    });
  }, [allTokens]);

  const filteredTokens = useMemo(() => {
    // Filter tokens based on the selected section
    const currentSection = ALL_PREFERENCE_SECTIONS[selectedSection];
    return tokensMap.filter((token) => {
      return (
        selectedSection === 'all' ||
        currentSection?.symbols.includes(token.symbol as any)
      );
    });
  }, [tokensMap, selectedSection]);

  const availableChains = useMemo(() => {
    return [...SUPPORTED_CHAINS].map(({ id }) => {
      const chain = getChain(id);

      // Map out the tokens that user has selected
      const _tokensMap = tokensMap.filter((token) => {
        return selectedTokens.some((t) => t.symbol === token.symbol);
      });

      const selectedTokensForChain = _tokensMap.filter(
        (token) => token.chainId === id || token.chains.includes(chain),
      );

      return { ...chain, selectedTokens: selectedTokensForChain };
    });
  }, [tokensMap, selectedTokens]);

  // If user has not set manually chains, then show all available chains
  // Otherwise, show only the manually selected chains
  const filteredChains = useMemo(() => {
    return selectedChains.length
      ? availableChains.filter((chain) =>
          selectedChains.includes(chain.chainId),
        )
      : availableChains;
  }, [availableChains, selectedChains]);

  const isAcceptableChain = useMemo(() => chainId === mainnet.id, [chainId]);

  const { tokenLogos, chainLogos } = useMemo(() => {
    return {
      tokenLogos: selectedTokens
        .map((t) => t.logoUri)
        .filter((uri) => uri !== undefined),
      chainLogos: filteredChains
        .map((c) => c.logoUri)
        .filter((uri) => uri !== undefined),
    };
  }, [filteredChains, selectedTokens]);

  // Every time something changes we cant to pull in the new requestChallenge. useQuery

  const handleSendSignedPreferences = useCallback(async () => {
    assert(address, 'No address');

    const uniqueTokenSymbols = selectedTokens.map((t) => t.symbol);

    mutate({
      chainIds: selectedChains,
      tokenSymbols: uniqueTokenSymbols,
    });
  }, [address, mutate, selectedChains, selectedTokens]);

  const { revokeSubname, isRevokeSubnamePending } = useRevokeSubname({
    chainId: 1,
    ensDomain: YODL_ENS_DOMAIN,
  });

  // Update the selected cards with the new preferences
  useEffect(() => {
    const tokenSymbols = preferences?.tokenSymbols || [];
    const chainIds = preferences?.chainIds || [];
    const selectedTokens = tokenSymbols
      .map((symbol) => allTokens.find((t) => t.symbol === symbol))
      .filter((t) => t !== undefined);
    const selectedChains = chainIds.filter((id) =>
      SUPPORTED_CHAINS.some((chain) => chain.id === id),
    );

    setSelectedTokens(selectedTokens);
    setSelectedChains(selectedChains);
  }, [allTokens, preferences]);

  // After payment preferences are saved, close the dialog
  useEffect(() => {
    if (status === 'success') {
      handleOnClose();
    }
  }, [status, handleOnClose]);

  return (
    <Flex
      direction="column"
      gap="4"
      style={{
        height: isMobile
          ? `calc(100dvh - ${FullScreenDialogHeaderHeight}px)`
          : '700px',
      }}
    >
      <JustanameAddressInput
        isLoading={isLoading}
        revokeSubnameMutation={() => {
          const [username, ensDomain] = splitDomain(
            debouncedUsername.debouncedValue,
          );

          revokeSubname({
            username,
            ensDomain,
          }).then(() => {
            window.location.reload();
          });
        }}
        onUsernameChange={setUsername}
        currentUsername={primaryName}
        isUsernameSet={Boolean(primaryName)}
        isValid={setIsValid}
      />

      {currentTab === 'token' && (
        <div id="filter-badges" className="flex gap-2">
          {Object.keys(ALL_PREFERENCE_SECTIONS).map((section) => {
            return (
              <Badge
                key={section}
                radius="full"
                color={selectedSection.includes(section) ? 'blue' : 'gray'}
                variant="soft"
                size="2"
                className="cursor-pointer"
                onClick={() => setSelectedSection(section as PreferenceSection)}
              >
                {ALL_PREFERENCE_SECTIONS[section].name}
              </Badge>
            );
          })}
        </div>
      )}

      {currentTab === 'token' &&
        ALL_PREFERENCE_SECTIONS[selectedSection]?.description && (
          <Text size="1" color="gray">
            {ALL_PREFERENCE_SECTIONS[selectedSection]?.description}
          </Text>
        )}

      {error?.message && (
        <Text size="1" color="red">
          {error.message}
          {error.name}
        </Text>
      )}

      {currentTab === 'chain' && (
        <Text size="2" color="gray">
          Choose your preferred payment chains. You can select multiple options.
          If you don't make a selection, all available chains will be utilized.
        </Text>
      )}

      <ScrollArea scrollbars="vertical" className="flex-grow relative">
        <Grid gap="2" p="1">
          {currentTab === 'token' &&
            filteredTokens.map((token, index) => {
              const isSelected = selectedTokens.some(
                (t) => t.symbol === token.symbol,
              );
              const chainImages = token.chains
                .map(({ chainId }) =>
                  parseTokenImages(getChain(chainId).logoUri),
                )
                .filter((uri) => uri !== undefined);

              const handleClick = () => {
                setSelectedTokens((prev) =>
                  isSelected
                    ? prev.filter((t) => t.symbol !== token.symbol)
                    : [...prev, token],
                );
              };

              return (
                <Card
                  key={index}
                  className={`cursor-pointer transition-all duration-150 no-scrollbar ${
                    isSelected
                      ? 'ring-1 ring-blue-500 shadow-md'
                      : 'hover:shadow-md'
                  }`}
                  onClick={handleClick}
                >
                  <Flex align="center" gap="3" justify="between">
                    <Flex align="center" gap="3">
                      <Image
                        width={IMAGE_SIZE}
                        height={IMAGE_SIZE}
                        src={token.logoUri || ''}
                        alt={token.name}
                        className="rounded-full"
                      />
                      <Grid gap="1">
                        <Text size="2" weight="medium">
                          {token.name}
                        </Text>
                        <Text size="1" color="gray">
                          {token.symbol}
                        </Text>
                      </Grid>
                    </Flex>
                    <Flex align="center">
                      <AvatarList size={24} uris={chainImages} />
                    </Flex>
                  </Flex>
                </Card>
              );
            })}
          {currentTab === 'chain' &&
            availableChains.map((chain, index) => {
              const isSelected = selectedChains.includes(chain.chainId);
              const selectedTokensUris = chain.selectedTokens
                .map((t) => t.logoUri)
                .filter((uri) => uri !== undefined);

              const handleClick = () => {
                setSelectedChains((prev) =>
                  isSelected
                    ? prev.filter((c) => c !== chain.chainId)
                    : [...prev, chain.chainId],
                );
              };

              return (
                <Card
                  key={index}
                  className={`cursor-pointer transition-all duration-150 no-scrollbar ${
                    isSelected
                      ? 'ring-1 ring-blue-500 shadow-md'
                      : 'hover:shadow-md'
                  }`}
                  onClick={handleClick}
                >
                  <Flex align="center" justify="between">
                    <Flex align="center" gap="3">
                      <Image
                        width={IMAGE_SIZE}
                        height={IMAGE_SIZE}
                        src={chain.logoUri || ''}
                        alt={chain.chainName}
                        className="rounded-full"
                      />
                      <Grid gap="1">
                        <Text size="2" weight="medium">
                          {chain.chainName}
                        </Text>
                        <Text size="1" color="gray">
                          {chain.chainId}
                        </Text>
                      </Grid>
                    </Flex>
                    <Flex align="center">
                      {selectedTokensUris.length > 0 && (
                        <AvatarList size={24} uris={selectedTokensUris} />
                      )}
                    </Flex>
                  </Flex>
                </Card>
              );
            })}
        </Grid>
      </ScrollArea>

      <Grid gap="3">
        <VisuallyHidden>
          <Card className="px-4 rounded-md bg-neutral-200 dark:bg-[--card]">
            <Grid columns="2">
              <Flex align="center" justify="between" gap="2">
                <Separator orientation="vertical" size="2" />
                <Flex
                  align="center"
                  gap="2"
                  onClick={() => setCurrentTab('chain')}
                  className="cursor-pointer"
                >
                  <div className="flex items-center gap-1">
                    {selectedChains.length > 0 && (
                      <GearIcon className="size-4" />
                    )}
                    <Text size="1">Chains</Text>
                  </div>
                  <AvatarList size={24} uris={chainLogos} />
                </Flex>
              </Flex>
            </Grid>
          </Card>
        </VisuallyHidden>

        <Button
          size="3"
          radius="full"
          onClick={() => {
            if (isAcceptableChain) {
              handleSendSignedPreferences();
            } else {
              switchChain({ chainId: mainnet.id });
            }
          }}
          loading={
            status === 'pending' ||
            switchChainPending ||
            isLoading ||
            isRevokeSubnamePending
          }
          color={savePreferencesButtonColorMap[status]}
          disabled={
            !selectedTokens.length ||
            !username ||
            !isValid ||
            status === 'pending' ||
            switchChainPending ||
            isLoading ||
            isRevokeSubnamePending
          }
        >
          {isAcceptableChain
            ? selectedTokens.length
              ? savePreferencesButtonText[status]
              : 'Select tokens'
            : 'Switch to Mainnet'}
        </Button>
      </Grid>
    </Flex>
  );
};
